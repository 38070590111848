<template>
  <div>
    <v-menu
      offset-y
      right
      transition="scale-transition"
      :close-on-content-click="true"
      z-index=1
    >
      <template v-slot:activator="{ on }">
        <v-badge
          overlap
          :value="selection.length"
          bordered
        >
          <v-btn
            fab
            small
            color="primary"
            v-on="on"
          >
            <icon-tooltip
              icon-name="i.Topics"
              :tooltip-text="$t('t.Topics')"
            />
          </v-btn>
          <template v-slot:badge>{{selection.length}}</template>
        </v-badge>
      </template>
      <v-card class="menu-card d-flex flex-column">
        <document-ref-list
          :action-callback="actionCallback"
          class="overflow-y-auto"
          :attach-quick-actions="false"
          :items="selection"
          :linkTarget="linkTarget"
          :docSelector="v => v.id"
          navigate
          v-on="$listeners"
        />
        <div class="d-flex flex-row align-center pa-4 pt-2">
          <v-btn
            small
            color="primary"
            class="buttonTopic"
            @click="createTopic"
          >
            <v-icon left>
              {{$icon('i.Topics')}}
            </v-icon>
            {{$t('t.CreateTopic')}}
          </v-btn>
          <v-btn
            small
            color="primary"
            class="ml-2 buttonTopic"
            @click="linkTopic"
          >
            <v-icon left>
              {{$icon('i.Link')}}
            </v-icon>
            {{$t('t.LinkTopic')}}
          </v-btn>
        </div>
      </v-card>
    </v-menu>
    <search-link
      v-model="showPopup"
      :link-target="linkTarget"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import { CacheType } from '@/wasm/pkg'
import sidePanelController from '@/sidePanelController'
import recentsController from '@/recentsController'
import tabController from '@/tabController'

export default {
  components: {
    DocumentRefList: () => import('@/components/document-ref-list'),
    SearchLink: () => import('@/pages/search/search-link'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      topics: [],
      isPropReceived: false,
      initialFiles: this.lodash.cloneDeep(this.selection),
      showPopup: false
    }
  },
  computed: {
    selectedTopics: {
      get () {
        return this.lodash.cloneDeep(this.selection)
      },
      set (selectedTopics) {
        this.$emit('update:selection', selectedTopics)
      }
    }
  },
  methods: {
    actionCallback () {
      this.$emit('update-links')
    },
    linkTopic () {
      this.showPopup = true
    },
    navigateToTopicSidePanel (id) {
      sidePanelController.cacheType = CacheType.TopicDetail
      sidePanelController.documentId = id
      sidePanelController.open()
    },
    createTopic () {
      recentsController.addTab(tabController.create(tabController.topicsTab(), { links: [{ id: this.linkTarget, grantUsersOf: true }] }), { documentType: 'accounts', documentId: this.$route.params.id })
    }
  },
  watch: {
    selection () {
      if (!this.isPropReceived) {
        this.isPropReceived = true
        this.initialTopics = this.lodash.cloneDeep(this.selection)
      }
    }
  },
  props: {
    readonly: Boolean,
    selection: {
      type: Array,
      default: _ => []
    },
    linkTarget: Object
  }
}
</script>

<style lang="stylus" scoped>
.menu-card
  max-height calc(100vh - 24px)
</style>
